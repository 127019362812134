import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';

import { FacebookFilled, InstagramFilled, TwitterSquareFilled } from '@ant-design/icons';

import LogoAdhoc from '~components/ui/LogoAdhoc';
import useAuthUser from '~utils/hooks/useAuthUser';

import s from './Footer.module.css';

const menu = [
  { label: 'Cartelera', url: '/' },
  { label: 'Promociones', url: '/promociones' },
  { label: 'Próximos estrenos', url: '/proximos-estrenos' },
  { label: 'CitiPremia', url: '/beneficios-citipremia' },
  { label: 'Complejos', url: '/complejos' },
  { label: 'Contacto', url: '/contacto' },
];
const socialMedia = [
  { name: 'facebook', url: 'https://www.facebook.com/Citicinemas', icon: <FacebookFilled /> },
  { name: 'twitter', url: 'https://twitter.com/Citicinemas', icon: <TwitterSquareFilled /> },
  { name: 'instagram', url: 'https://instagram.com/citicinemasmx/', icon: <InstagramFilled /> },
];
const Footer: FC = () => {
  const user = useAuthUser();
  return (
    <footer className={s.root}>
      <div className={s.main}>
        <div className={s.apps}>
          <span>¡Descarga la App de Citicinemas GRATIS!</span>
          <div className="flex justify-center space-x-3">
            <Link
              href="https://play.google.com/store/apps/details?id=com.citicinemas.citicinemasapp"
              target="_blank"
            >
              <Image
                src="/img/playstore.png"
                alt="Google Play Store"
                width={120}
                height={38}
                sizes="120px"
              />
            </Link>
            <Link href="https://appsto.re/mx/gCy59.i" target="_blank">
              <Image
                src="/img/appstore.png"
                alt="Apple AppStore"
                width={120}
                height={38}
                sizes="120px"
              />
            </Link>
          </div>
        </div>
        <div>
          <ul className={s.menu}>
            {menu.map((item) => {
              if (user && item.label === 'CitiPremia') {
                return null;
              } else {
                return (
                  <li key={item.url}>
                    <Link href={item.url}>{item.label}</Link>
                  </li>
                );
              }
            })}
          </ul>
          <div className={s.social}>
            {socialMedia.map((item) => (
              <Link key={item.name} href={item.url} target="_blank" rel="noreferrer">
                {item.icon}
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className={s.copy}>
        <div>
          ©Copyright 2022. Todos los derechos reservados a Citicinemas® |{' '}
          <a href="/aviso-privacidad" target="_blank">
            Aviso de privacidad
          </a>{' '}
          |{' '}
          <a href="/docs/citipremia-terminos.pdf" target="_blank">
            Términos y condiciones
          </a>
        </div>
        <Link
          href="https://adhocti.com/"
          className={s.adhoc}
          target="_blank"
          title="Desarrollo de Apps y Sitios web"
        >
          <span>Powered by</span> <LogoAdhoc className="text-lg" />
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
